<template>
  <div class="wrapper">
    <h1>{{ $t('buying_crypto') }}</h1>
    <p v-html="$t('this_guide_will_explain')" />
    <h3>1. {{ $t('deposit') }}</h3>
    <p v-html="$t('click_on_the_deposit_button')" />
    <img
      src="@/assets/images/banners/deposit-instructions-1.png"
      alt="screenshot instructions"
    />
    <h3>2. {{ $t('select_a_payment_method') }}</h3>
    <p v-html="$t('fill_in_the_details', {dynamicDomain: dynamicDomain})" />
    <img
      src="@/assets/images/banners/deposit-instructions-2.png"
      alt="screenshot instructions"
    />
    <h6 style='margin-top: 2rem;'>1. {{ $t('crypto') }}</h6>
    <p v-html="$t('to_fund_your_account_using_crypto')" />
    <h6>2. {{ $t('credit_cards_and_ewallets') }}</h6>
    <p v-html="$t('to_fund_your_account_using_classic')" />
  
    <h3>3. {{ $t('account_credit') }}</h3>
    
    <p v-html="$t('your_bitcoin_will_take')" />
    <h3>{{ $t('ready_to_go') }}</h3>
    <p>{{ $t('fund_your_account_using') }}</p>
    <button @click="renderDepositDialog">
      {{ $t('deposit_now') }}
    </button>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import Cookies from 'js-cookie';

  @Component
  export default class HowToDeposit extends Vue {
    get dynamicDomain() {
      const currentUrl = window.location.href;
      if (currentUrl.includes("bitcoin.com")) {
        return "bitcoin.com";
      } else {
        return "bitcoingames1.com";
      }
    }

    mounted() {
      const urlParams = this.$route.query;
      if (urlParams.btag !== undefined) {
        Cookies.set('btag', urlParams.btag, { expires: 30 });
      }
    }

    renderDepositDialog() {
      const depositButton = document.getElementById('header-control-deposit');
      if (depositButton) {
        depositButton.click();
      } else {
        console.warn('Deposit button not found');
      }
    }
  }
</script>

<style scoped>
* {
  padding: 0 1rem;
  font-family: 'Gilroy-Regular', sans-serif;
}

h1 {
  margin: 2rem 0;
  font-weight: 700;
}

h3,
h6 {
  font-weight: 700;
  margin: 4rem 0 2rem 0;
}

img {
  max-width: 100%;
  margin-top: 12px;
}

button {
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 1rem 0 0 1rem;
  background: #0ac18e;
}

.wrapper {
  width: 700px;
  padding: 75px 0 75px 100px;
}

@media only screen and (max-width: 900px) {
  .wrapper {
    padding: 0;
    width: 100%;
  }
}
</style>
