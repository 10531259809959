
































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {namespace} from "vuex-class";
import { QrcodeStream } from 'vue-qrcode-reader'
import CopySpan from '@/components/macros/CopySpan.vue';

const cookies = require('js-cookie')
const SettingsGetter = namespace('settings').Getter
const SettingsAction = namespace('settings').Action
const AccountGetter = namespace('account').Getter
const AccountAction = namespace('account').Action
const AccountMutation = namespace('account').Mutation
const ActivePromotionsCampaignGetter = namespace('activePromotionsCampaign').Getter
const ActivePromotionsCampaignAction = namespace('activePromotionsCampaign').Action


const AppProps = Vue.extend({
  data: () => {
    return {
        loadingQrGoogleApi: true,
        isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
          navigator.userAgent
        ),
        viewPassword: 0,
        viewPasswordRepeat: 0,
        viewOldAccountPassword: 0,
        showQRScanner: false,
        validationErrorsLengthErrClass: '',
        validationErrorsUppercaseErrClass: '',
        validationErrorsLowercaseErrClass: '',
        validationErrorsDigitErrClass: '',
        currentSecuritySelectedTabIndex: 0,
        editingPassword: false,
        shared_secret: '',
        fixed_withdrawal_address: '',
        set_fixed_withdrawal_address_error: '',
        password: '',
        password_repeat: '',
        set_password_msg_neg: '',
        set_password_msg_pos: '',
        logout_upon_password_update: true,
        old_password: '',
        fixed_withdrawal_address_confirm: ''
    }
  },
  computed: {
    securityLevel() {
      let secAmount = Number(this.securedByPassword) + Number(this.twofaAuthEnabled) + Number(this.fixedWithdrawalAddress.length > 0);
      switch (secAmount) {
        case 0:
        case 1:
          return "LOW"
      case 2:
        return this.isLegacyUser ? "MEDIUM" : "HIGH"
      case 3:
        return "HIGH"
      default:
        return ""
      }
    },
    securityNavOptionsI18nKeys() {
      if (!this.isLegacyUser) {
        return ['password_nma', 'fixed_withdrawal_address_nma'];
      } else {
        return ['password_nma', '2fa_authentication_nma', 'fixed_withdrawal_address_nma'];
      }
    },
    securityNavOptions() {
      if (!this.isLegacyUser) {
        return ['Password', 'Fixed Withdrawal Address'];
      } else {
        return ['Password', '2FA Authentication', 'Fixed Withdrawal Address'];
      }
    },
    dynamicDomain() {
      const currentUrl = window.location.href;
      if (currentUrl.includes("bitcoin.com")) {
        return "bitcoin.com";
      } else {
        return "bitcoingames1.com";
      }
    },
  },
  async mounted () {
    await setTimeout(async () => {
      if(cookies.get('isMagicTokenLogin') === "true") {
        await this.getMagicTokenAndAuthData();
      }
      await this.getUserData();
      this.fixed_withdrawal_address = await this.getFixedWithdrawalAddress();
      if (!this.twofaAuthEnabled) {
        let res = await this.getTotpSecretKey()
        if (res.success) {
          this.shared_secret = res.secretKey
        }
      }
    }, 3000);
  },
  methods: {
    showModal () {
        this.$modal.show('disable2fa-modal');
    },
    hideModal () {
        this.$modal.hide('disable2fa-modal');
    },
    selectSecurityTab(index:number) {
      this.currentSecuritySelectedTabIndex = index;
    },
    validatePassword (pw, pw_repeat) {
      this.set_password_msg_neg = ""
      this.set_password_msg_pos = ""
      if (!pw || !pw_repeat) return { valid: false, msg: this.$i18n.t('please_input_both_password'), err: 'empty' }
      if (pw != pw_repeat) return { valid: false, msg: this.$i18n.t('password_and_repeated_password'), err: 'nomatch' }
      if (pw.length < 8) return { valid: false, msg: this.$i18n.t('password_is_too_short'), err: 'length' }
      if (pw === this.old_password || pw_repeat === this.old_password) return {
        valid: false,
        msg: this.$i18n.t('current_and_new_password_nma'),
        err: 'nodifference'
      }
      let regex = new RegExp("^((?=.*)(?=.*[a-z])(?=.*[A-Z]).{8,32})$")
      if (!regex.test(pw)) return { valid: false, msg: this.$i18n.t('password_should_contain_at_least') }
      return { valid: true }
    },
    showValidationHelpers() {
      let lengthErr = this.password.length < 8;
      let uppercaseErr = !/[A-Z]/gm.test(this.password);
      let lowercaseErr = !/[a-z]/gm.test(this.password);
      let digitErr = !/[0-9]/gm.test(this.password);
      if (!this.password.length) {
        this.validationErrorsLengthErrClass = 'text-normal';
      } else {
        this.validationErrorsLengthErrClass = lengthErr ? 'text-error' : 'text-must-have-success';
      }

      if (!this.password.length) {
        this.validationErrorsUppercaseErrClass = 'text-normal';
      } else {
        this.validationErrorsUppercaseErrClass = uppercaseErr ? 'text-error' : 'text-must-have-success';
      }

      if (!this.password.length) {
        this.validationErrorsLowercaseErrClass = 'text-normal';
      } else {
        this.validationErrorsLowercaseErrClass = lowercaseErr ? 'text-error' : 'text-must-have-success';
      }

      if (!this.password.length) {
        this.validationErrorsDigitErrClass = 'text-normal';
      } else {
        this.validationErrorsDigitErrClass = digitErr ? 'text-error' : 'text-must-have-success';
      }


    },
    async setPassword () {
      this.set_password_msg_neg = ""
      this.set_password_msg_pos = ""
      let val = this.validatePassword(this.password, this.password_repeat)
      if (!val.valid) {
        this.set_password_msg_neg = val.msg
        return
      }
      if (this.logout_upon_password_update) {
        if (!confirm(this.$i18n.t('you_chose_to_be_logged_out'))) {
          return
        }
      }
      let payload = {
        newPassword: this.password,
        oldPassword: this.old_password,
        hasMagicToken: this.hasMagicToken
      }
      try {
        this.set_password_msg_pos = this.$i18n.t('loading');
        await this.updatePassword(payload)
        this.set_password_msg_pos = this.$i18n.t('password_changed');
        setTimeout(() => {
          this.set_password_msg_pos = "";
        }, 5000);
        this.password = ''
        this.password_repeat = ''
        this.old_password = ''
        if (this.logout_upon_password_update) {
          this.set_password_msg_pos += this.$i18n.t('logging_out');
          this.logout(true)
        }
      } catch (e) {
        this.set_password_msg_pos = ""
        this.set_password_msg_neg = e.toString()
      }
    },
    async logout(isPasswordChange=false) {

      const authToken = cookies.get('token');

      await this.logoutUser(authToken);

      this.setIsLoggedIn(false)
      this.setHasMagicToken(false)
      this.setMagicToken('')
      this.setToken('')
      this.setUserId('')

      let token = cookies.get('magicToken')
      cookies.remove('magicToken')
      cookies.remove('token')
      cookies.remove('banner_id')
      cookies.remove('play_real_dialog_shown')
      cookies.remove('userId')
      cookies.remove('casino_code')
      cookies.remove('isMagicTokenLogin')
      sessionStorage.removeItem('session-id')
      if (isPasswordChange) {
        window.history.replaceState({}, document.title, "/?account_key=" + token + '&no_warning=true');
        this.$router.go(0)
      } else {
        let query = Object.assign({}, this.$route.query);
        delete query.account_key;
        this.$router.replace({query});
        location.href = "/"
      }
    },
    twoFaInput (fieldIndex) {
      let digit = this.$refs['twoFaInput_' + fieldIndex][0]
      if (digit.value == '' || isNaN(parseInt(digit.value))) {
        digit.value = ''
      } else if (fieldIndex < 6) {
        let twofa = this.$refs['twoFaInput_' + (fieldIndex + 1)][0]
        if (twofa) twofa.focus()
      }
    },
    async confirmConfigure2fa () {
      if (this.twofaAuthEnabled) {
        console.log("Two FA already enabled")
        return;
      }

      let code = '';
      for (let i = 1; i <= 6; i++)
        code += $("#input-2fa > input[tabindex=" + i + "]").val()

      if (code.length != 6) {
        alert("Please fill in all of the digits!")
        return;
      }

      let res = await this.enableTwoFactorAuth(code)
      if (res.success) {
        alert(this.$i18n.t('your_account_now_has'));
        $(".dialog").trigger('close')

      } else if (res.error !== undefined) {
        if (res.error === 'codeInvalid') {
          alert(this.$i18n.t('the_code_you_provided'))
          for (let i = 1; i <= 6; i++)
            $('#input-2fa > input[tabindex=' + i + ']').val('')
          $('#input-2fa > input[tabindex=1]').focus()
        } else if (res.error === 'alreadyEnabled') {
          alert(this.$i18n.t('two_step_authenticator_is'));
        }
      }
    },
    async submitFixedWithdrawalAddress () {
      const observer = this.$refs.fixed_withdrawal_address_observer
      if (!observer.errors.withdrawal_address[0] && !observer.errors.withdrawal_address_confirm[0]) {
        let res = await this.setFixedWithdrawalAddress(this.fixed_withdrawal_address)
        if (res.error) {
          this.set_fixed_withdrawal_address_error = this.$i18n.t('error_setting_fixed_withdrawal')
        }
      }
    },
    onDecode (decodedString) {
      this.showQRScanner = false;
      this.fixed_withdrawal_address = decodedString;
    },
  }
})

@Component({
  components: {
    QrcodeStream,
    CopySpan
  }
})

export default class WithdrawalPanel extends AppProps {
  @AccountAction logoutUser

  @AccountMutation setIsLoggedIn
  @AccountMutation setMagicToken
  @AccountMutation setHasMagicToken
  @AccountMutation setToken
  @AccountMutation setUserId      
  @SettingsGetter getPlatformCurrency!: String
  @SettingsGetter getWithdrawalServiceFee
  @SettingsAction getWithdrawRules
  @AccountGetter hasUnconfirmedTx
  @AccountGetter twofaAuthEnabled
  @AccountGetter securedByPassword
  @AccountGetter legacyPassword
  @AccountGetter isLegacyUser
  @AccountGetter userBalance: Number
  @AccountGetter hasMagicToken
  @AccountAction getUserData
  @AccountAction getTotpSecretKey
  @AccountAction getFixedWithdrawalAddress
  @AccountAction setFixedWithdrawalAddress
  @AccountAction getMagicTokenAndAuthData
  @AccountAction updatePassword
  @AccountAction enableTwoFactorAuth
  @AccountGetter userId!: String
  @SettingsGetter getPlatformName!: String
  @AccountAction createWithdrawal
  @AccountGetter magicToken!: String
  @AccountGetter fixedWithdrawalAddress!: String
  @ActivePromotionsCampaignGetter getAnyActivePromoRewardUUID
  @ActivePromotionsCampaignAction getActiveBonuses
  @ActivePromotionsCampaignAction cancelActiveBonus
  @ActivePromotionsCampaignAction cancelActiveFreeSpins
}
