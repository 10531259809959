






















import { Component, Vue } from 'vue-property-decorator'

const AppProps = Vue.extend({
  computed: {
    dynamicDomain() {
      const currentUrl = window.location.href;
      if (currentUrl.includes("bitcoin.com")) {
        return "bitcoin.com";
      } else {
        return "bitcoingames1.com";
      }
    },
  },
})
@Component({
  mounted () {
    let twitterScript = document.createElement('script')
    twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
    document.head.appendChild(twitterScript)
  },
  components: {},
  methods: {}
})
export default class ShortFooter extends AppProps {

}
