

























































































































































































































































































































































































































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import Header from "./components/headers/Header.vue";
import SideNav from "./components/headers/SideNav.vue";
import Footer from "./components/footers/Footer.vue";
import ShortFooter from "./components/footers/ShortFooter.vue";
import FooterMobile from "./components/footers/FooterMobile.vue";
import BitcoinPrice from "./components/footers/BitcoinPrice.vue";
import HomeGameMenu from "./components/menus/HomeGameMenu.vue";
import HomeGameBanner from "./components/banners/HomeGameBanner.vue";
import DepositDialog from "./components/dialogs/DepositDialog.vue";
import NewDepositDialog from "./components/dialogs/NewDepositDialog.vue";
import NewDepositCanvas from "./components/dialogs/NewDepositCanvas.vue";
import TargetedPromotionDialog from "./components/dialogs/TargetedPromotionDialog.vue";
import MobileTargetedPromotionDialog from "./components/dialogs/MobileTargetedPromotionDialog.vue";
import NewRestrictedRegion from "./components/dialogs/NewRestrictedRegion.vue";
// import VerifyEmailDialog from "./components/dialogs/VerifyEmailDialog.vue";
import CreateAccountDialog from "./components/dialogs/CreateAccountDialog.vue";
import VerifyGame from "./components/dialogs/VerifyGame.vue";
import ExpectedReturnDialog from "./components/dialogs/ExpectedReturnDialog.vue";
import PlayRealDialog from "./components/dialogs/PlayRealDialog.vue";
import FirstTimeDialog from "./components/dialogs/FirstTimeDialog.vue";
import { namespace } from "vuex-class";
import Common from "@/mixins/Common.vue";
import { loadLanguageAsync } from "@/helpers/i18n"
import DepositIncomingDialog from "@/components/dialogs/DepositIncomingDialog.vue";
import moment from "moment";
import AccountModalDialog from "@/components/dialogs/AccountModalDialog.vue";
import PasswordReset from "@/pages/PasswordReset.vue";
import PopUp from './components/global/PopUp.vue'
import Button from './components/global/Button.vue'
import gql from 'graphql-tag'
import countryLocaleMapping from '@/constants/countryLocaleMapping'

const cookies = require('js-cookie')

require("@/assets/css/common.css");
require("@/assets/css/base.css");
require("@/assets/css/icons.css");

const AccountGetter = namespace('account').Getter
const AccountAction = namespace('account').Action
const AccountMutation = namespace('account').Mutation
const SettingsGetter = namespace('settings').Getter
const GamesGetter = namespace('game').Getter
const GamesAction = namespace('game').Action
const GameLogsAction = namespace("gameLogs").Action
const UIGetter = namespace('ui').Getter
const UIMutation = namespace('ui').Mutation
const DepositGetter = namespace("deposit").Getter
const AvailablePromotionsCampaignAction = namespace('availablePromotionsCampaign').Action
const ActivePromotionsCampaignAction = namespace('activePromotionsCampaign').Action
const ActivePromotionsCampaignGetter = namespace('activePromotionsCampaign').Getter
const PastPromotionsCampaignAction = namespace('pastPromotionsCampaign').Action
const DepositAction = namespace("deposit").Action

const AppProps = Vue.extend({
  mixins: [Common, ],
  data: function() {
    return {
      interval: null,
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent),
      freespinMessage: {
        title: "",
        body: "",
        startDate: "",
        lifetime: 0
      },
      targetedPromotion: {},
      renderMobileTargetedPromotionDialog: false,
      blurBackground: false,
      userIpCheckDone: false,
      firstPoll: true,
      showCreateAccountDialog: false,
      showPlayRealDialog: false,
      showFirstTimeDialog: false,
      verifiedForBannerCampaign: false,
      accountModalSelectedTab: 0,
      renderWelcomeOffer: false,
      verificationEmailResent: false,
      resendTimer: 30,
      VUE_APP_STRAPI_API_URL: process.env.VUE_APP_STRAPI_API_URL,
      forgottenPasswordEmailResent: false,
    }
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Bitcoin.com Games is an online casino where you can play Slots, Video Poker, Roulette, Blackjack & more using BTC & BCH.
                🎰Instant Cashouts | Provably Fair | Live Casino | Progressive Jackpots🎰`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  components: {
    PasswordReset,
    DepositIncomingDialog,
    Header,
    SideNav,
    Footer,
    FooterMobile,
    ShortFooter,
    HomeGameMenu,
    HomeGameBanner,
    DepositDialog,
    NewRestrictedRegion,
    NewDepositDialog,
    NewDepositCanvas,
    CreateAccountDialog,
    // VerifyEmailDialog,
    VerifyGame,
    ExpectedReturnDialog,
    PlayRealDialog,
    FirstTimeDialog,
    AccountModalDialog,
    BitcoinPrice,
    PopUp,
    Button,
    TargetedPromotionDialog,
    MobileTargetedPromotionDialog,
  },
  computed: {
    dynamicDomain() {
      const currentUrl = window.location.href;
      if (currentUrl.includes("bitcoin.com")) {
        return "bitcoin.com";
      } else {
        return "bitcoingames1.com";
      }
    },
  },
  watch: {
    isLoggedIn: async function (isLoggedIn) {
      if(isLoggedIn) {
        await this.afterLoginLoad()
      }
    },
    blockedCountriesList: async function (blockedCountriesList) {
      try {
        for (var i = 0; i <= blockedCountriesList.length; i++) {
          if (blockedCountriesList[i].countryCode === this.userCountryCode) {
            this.$bvModal.show('new-restricted-region-modal')
            break;
          }
        }
      } catch(e) {
        console.error(e, 'error')
      }
    }
  },
  async mounted() {
    await this.userIpCheck()
    await this.getBlockedCountries()
    this.userIpCheckDone = true
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.setUserIsActive(false)
      }
      else {
        this.setUserIsActive(true)
      }
    });
    if (!cookies.get("userLanguage")) {
      const countryCode = this.userCountryCode.toUpperCase()
      if (
        Object.keys(countryLocaleMapping).includes(countryCode)
      ) {
        const language = countryLocaleMapping[countryCode];
        cookies.set("userLanguage", language);
      }
    }
    this.isMobile = /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent);
    setTimeout(async () => {
      let registrationDate;
      let currentDate = moment();
      let optedInCampaigns;
      let isAnyWelcomePromotionOptedIn = false;
      if (this.isLoggedIn){
        // @ts-ignore
        const { data: { registrationDate: date } } = await this.getUserData()
        registrationDate = moment(date)
        optedInCampaigns = await this.getOptedInCampaigns()
      }
      if (optedInCampaigns) {
        isAnyWelcomePromotionOptedIn = this.checkIsAnyWelcomePromoOptedIn(optedInCampaigns.content)
      }
      if (
        this.isLoggedIn === true &&
        this.depositHistory.length === 0 &&
        currentDate.diff(registrationDate, 'days') < 7 &&
        this.promotions.length > 0 &&
        !isAnyWelcomePromotionOptedIn &&
        localStorage.getItem('welcomeOffer') !== 'true'
      ) {
        this.renderWelcomeOffer = true
      }
    }, 2500)

    this.$router.onReady(async () => {
      if (!cookies.get("userLanguage")) {
        if (this.$route.params.locale) {
          cookies.set("userLanguage", this.$route.params.locale)
        }
        if(cookies.get("userLanguage") === undefined) {
          let lang = window.navigator.language.split('-')[0] || 'en';
          cookies.set("userLanguage", lang);
        }
      }

      let urlParams = this.$route.query;
      let accountKey = urlParams.account_key;
      let noWarning = urlParams.no_warning;

      if (accountKeyLegacy !== undefined && accountKeyLegacy !== 'undefined' && !this.isLoggedIn){
        await this.loginLegacy(accountKeyLegacy);
      } else if (accountKey !== undefined && accountKey !== 'undefined') {
        await this.handleAccountKey(accountKey, noWarning);
      }

      // Store btag in a cookie if it's present;
      // it will be stored by casino api/associated to user on the first login if the user is not registered yet
      if (urlParams.btag !== undefined) {
        cookies.set('btag', urlParams.btag, { expires: 30 })
        let affId = urlParams.btag.split('_')[0];
        if (affId.length >= 6){
          let res = await fetch(`${this.VUE_APP_STRAPI_API_URL}/affiliate-btags?btag_contains=${affId}`, { method: 'GET' });
          let affiliateBtags = await res.json();
          if (affiliateBtags.length) {
            cookies.set('affiliateBtagPromoId', affiliateBtags[0].promoId, { expires: 1 });
            this.$router.push({ path: 'signup', query: { affiliatePromo: affiliateBtags[0].promoId } });
          }
        }
      }

      // Store banner id for promotion purposes if it's present;
      // it will be used to auto opt in banner campaign on the first login if the user is not registered yet
      if (urlParams.banner_id !== undefined) {
        cookies.set('banner_id', urlParams.banner_id, { expires: 30 })
      }

      if (urlParams.openMyAccount) {
        // @ts-ignore
        $("#account_modal").modal();
      }
    })

    let accountKeyLegacy = cookies.get('magicToken')

    this.$root.$on('setAccountModalTab', idx => {
      this.setAccountModalTab(idx)
    })

    if (accountKeyLegacy !== undefined && accountKeyLegacy !== 'undefined') {
      this.setIsLegacyUser(true);
    }
    await loadLanguageAsync(cookies.get("userLanguage"));
    await this.checkForCustomHooks()
    if (this.isLoggedIn) {
      await this.afterLoginLoad()
    }
  },
  apollo: {
    affiliateBtags: {
      query: gql`
        query {
          affiliateBtags {
            btag
          }
        }
      `,
    },
    targetedPromotion: {
      query: gql`
        query TargetedPromos($casinoCode: String!, $status: String!) {
          casinos(where: { name: $casinoCode }) {
            targeted_promotions(where: { status: $status }) {
              id,
              terms_and_conditions
              campaign_name
              description
              user_ids
              cta_button_text
              cta_button_text_ru
              cta_button_text_es
              cta_button_text_pt
              campaignUUID
              isOptIn
              cta_link
              type
              start_date
              end_date
              desktop_image {
                url
              }
              mobile_image {
                url
              }
              description_ru
              description_es
              description_pt
            }
          }
        }
      `,
      variables: () => {
        return {
          casinoCode: cookies.get('casino_code'),
          status: process.env.VUE_APP_WMS_URL.includes("btctest.net") ? "draft" : "published"
          // status: "draft"
        }
      },
      fetchPolicy: "no-cache",
      update: data => {
        if (data && data.casinos.length && data.casinos[0].targeted_promotions) {
          const targeted_promotions = data.casinos[0].targeted_promotions;
          let promotionForUser = targeted_promotions.filter(tp => tp.user_ids.includes(cookies.get('userId')))
          if(promotionForUser.length) {
            return promotionForUser[0];
          }
          else {
            return {};
          }
        }
      }
    },
    promotions: {
      query: gql`
        query Promos($casinoCode: String!, $status: String!) {
          casinos(where: { name: $casinoCode }) {
            promotions(where: { status: $status, isPopUp: true }) {
              description
              description_ru
              description_es
              description_pt
              buttonText
              buttonText_ru
              buttonText_es
              buttonText_pt
              uuid
              status
              isPopUp
              terms_and_condition
              terms_and_condition_ru
              terms_and_condition_es
              terms_and_condition_pt
              WelcomeOfferType
              welcome_offer_description
              welcome_offer_description_ru
              welcome_offer_description_es
              welcome_offer_description_pt
            }
          }
        }
      `,
      variables: () => {
        return {
          casinoCode: cookies.get('casino_code'),
          status: process.env.VUE_APP_WMS_URL.includes("btctest.net") ? "draft" : "published"
        }
      },
      fetchPolicy: "no-cache",
      update: data => data.casinos[0].promotions
    }
  },

  methods: {
    async resendEmail() {
      this.interval = setInterval(this.decrementTime, 1000);
      this.verificationEmailResent = true
      this.reconfirmEmailPublic(cookies.get('unverified_email'));
      setTimeout(() => {
        clearInterval(this.interval)
        this.resendTimer = 30
        this.verificationEmailResent = false
      }, 30000)
    },
    decrementTime() {
      this.resendTimer = parseInt(this.resendTimer) - 1;
    },
    openDeposit() {
      // @ts-ignore
      if(this.isLoggedIn) {
        this.$router.push({ name: 'promotion' });
      } else {
        this.$router.push({ name:'player-login' })
      }
      this.$modal.hide('verified-email-modal');
    },
    async afterLoginLoad() {

      const res = await fetch(
           // @ts-ignore
          `${this.VUE_APP_STRAPI_API_URL}/targeted-promotions/findOneByPlayerId?player_id=${cookies.get('userId')}`, { method: 'GET' },
        );
        let targetedPromotionsData = await res.json();
        if (process.env.VUE_APP_WMS_URL.includes("btctest.net")) {
          targetedPromotionsData = targetedPromotionsData.filter((tp:any) => tp.status === 'draft')
        } else {
          targetedPromotionsData = targetedPromotionsData.filter((tp:any) => tp.status === 'published')
        }
        if (targetedPromotionsData.length) {
          // @ts-ignore
          this.targetedPromotion = targetedPromotionsData.find((tp:any) => {
            const today = moment();
            const startDate = moment(tp.start_date);
            const endDate = moment(tp.end_date);
            return today.isBetween(startDate, endDate);
          });
          // @ts-ignore
          if (this.targetedPromotion){
            const today = moment();
            // @ts-ignore
            const startDate = moment(this.targetedPromotion.start_date)
            // @ts-ignore
            const endDate = moment(this.targetedPromotion.end_date)
            if(today.isBetween(startDate, endDate)
            // @ts-ignore
            && cookies.get(`tpromotiondialogshown-${this.targetedPromotion.id}`) !== 'true') {
              // @ts-ignore
              if(!this.isMobile) {
                // @ts-ignore
                $('#targeted_promotion_modal').modal()
              } else {
                  // @ts-ignore
                  this.renderMobileTargetedPromotionDialog = true;
              }
            }
          }
        }

        // @ts-ignore
        await this.startPollingTxs();
        // @ts-ignore
        await this.startPollingDeposits();
        // @ts-ignore
        await this.startPollingUserBalance();
        // @ts-ignore
        await this.startCasinoSession();
        // @ts-ignore
        await this.getFreespins();
        // @ts-ignore
        await this.getUserData();

        // Users created after login feature all have a password set as it's a requirement to signup
        // @ts-ignore
        if (!this.isLegacyUser) {
          // @ts-ignore
          this.setSecuredByPassword(true);
        }
    },
    async claimCampaign(campaignUUID) {
      // @ts-ignore
      let userId = this.userId;
      let payload = {
        'campaignUUID': campaignUUID,
        'userId': userId
      }

      // @ts-ignore
      const res = await this.checkIsCampaignEligible(payload)

      if (res.data.optInDate !== null) {
        localStorage.setItem('welcomeOffer', 'true');
        // if user has already opted in
        // @ts-ignore
        this.renderWelcomeOffer = false
        // @ts-ignore
        this.showIframeDepositPopup()
      } else {
        // @ts-ignore
        const res2 = await this.claimPromotionCampaign(campaignUUID)

        if (res2.success) {
          localStorage.setItem('welcomeOffer', 'true');
          // @ts-ignore
          this.renderWelcomeOffer = false
          // @ts-ignore
          this.showIframeDepositPopup()
        } else {
          window.alert("Something went wrong. Please contact support")
        }
      }
      await this.getUserData()
      if (this.isLoggedIn) {
        await this.getAvailablePromotions(cookies.get('banner_id'))
        await this.getActiveBonuses()
        await this.getPastBonuses()
        //Fetching the active bonuses at every 5 sec.
        let that = this;
        this.interval = setInterval(async function () {
          await that.getActiveBonuses();
        }, 20000);
      } else {
        await this.getGeneralPromotions()
      }
    },
    beforeContactModalClose() {
      this.blurBackground = false;
      if (cookies.get('unverified_email')) {
        this.$modal.show('verify-email-modal');
      }
    },

    async showIframeDepositPopup() {
      // eslint-disable-next-line
      ($('#pre_deposit_dialog') as any).modal('show');
      // @ts-ignore
      await this.getDepositAddress();
    },

    buttonText(promo) {
      return promo[this.$i18n.locale === 'en' ? 'buttonText' : `buttonText_${this.$i18n.locale}`]
    },

      onChildClick (value) {
        // @ts-ignore
        this.showFirstTimeDialog = value
      },
      async checkForCustomHooks() {
        if(this.$route.query.deposit || this.$route.query.convert || this.$route.query.buy ) {
          // @ts-ignore
          if (!this.userAddress.length && !this.limitedAccount) {
            // @ts-ignore
            await this.getDepositAddress();
          }
          ($("#header-control-deposit") as any).click();
        }
      },
      async getFreespins() {
        // @ts-ignore
        let freespins = await this.getUserFreeSpins();
        if(!freespins.error) {
          // @ts-ignore
          await this.getPublicGamesList();
          // @ts-ignore
          let messages = freespins.map((freespin) => {
            let gameUrl = freespin.gameId === 'slots' ? '/slots' : ('/3game?game_id=' + freespin.gameId)
            // @ts-ignore
            let body = `We have awarded you ${freespin.amount} Free Spins for <a href="${gameUrl}" target="_blank">${this.getFreeSpinGameName(freespin.gameId)}</a>.`;
            return {
              title: "You have been awarded Free Spins!",
              body: body,
              startDate: freespin.creationDate,
              lifetime: freespin.lifetime,
              id: freespin.uuid,
            };
          });

          if (messages.length) {
            // @ts-ignore
            if (this.showFreespinDialog(messages, 0)) return;
          }
          // @ts-ignore
          let that = this;
          setTimeout(function () {
            //@ts-ignore
            that.getFreespins();
          }, 3000);
        }
      },
      showFreespinDialog(freespinMessages, index) {
        // @ts-ignore
        let freespinMessage = freespinMessages[index];
        // @ts-ignore
        if (this.getFreespinsSeen.indexOf(freespinMessage.id) === -1) {
          // @ts-ignore
          this.freespinMessage = freespinMessage;
          // @ts-ignore
          $("#freespin-dialog").lightbox_me({
            centered: true,
            onClose: () => {
              if (freespinMessages.length >= index + 1)
                // @ts-ignore
                this.showFreespinDialog(freespinMessages, index + 1);
            },
          });
          // @ts-ignore
          this.setFreespinSeen(freespinMessage.id);
          return true;
        }
        return false;
      },
      async startCasinoSession() {
        // @ts-ignore
        await this.initializeCasinoSession();
        // @ts-ignore
        setTimeout(() => this.startCasinoSession(), 1740000);
      },
      async startPollingUserBalance() {
        let currentPath = window.location.pathname;
        if (currentPath != "/3game") {
          let pollingPaths = [
            "/", "/home", "/promotion", "/angrybanker"
          ]
          // @ts-ignore
          if(this.userIsActive && (pollingPaths.includes(currentPath) || this.firstPoll)) {
            // @ts-ignore
            this.firstPoll = false
            // @ts-ignore
            await this.getUserBalance();
          }
          let that = this;
          // @ts-ignore
          setTimeout(() => that.startPollingUserBalance(), that.userIsActive ? 60000 : 10000);
        }
      },
      async startPollingTxs() {
        // @ts-ignore
        if(this.userIsActive) {
          // @ts-ignore
          await this.hasPendingTx();
          // @ts-ignore
          if (this.hasUnconfirmedTx) {
            // @ts-ignore
            this.getUserBalance()
          }
        }
        // @ts-ignore
        setTimeout(() => this.startPollingTxs(), this.userIsActive ? 10000 : 5000);
      },
      async startPollingDeposits() {

        const payload = {
          //@ts-ignore
          platformCurrency: this.getPlatformCurrency
        }
        // @ts-ignore
        const newDeposit = await this.getDepositsData(payload);

        if(newDeposit) {
          if(newDeposit?.paymentId) {
            //@ts-ignore
            await this.updateLastDeposit({ref: newDeposit.paymentId});
          }

          this.$toast.open({
            message: this.$t('thank_you_for_your_deposit'),
            type: 'success',
            duration: 5000,
            position: 'top-right'
          })
        }

        // @ts-ignore
        setTimeout(() => this.startPollingDeposits(), 10000);
      },
      async loginLegacy(key) {
          // @ts-ignore
          await this.retrieveJWTFromAccountKey({ accountKey: key });
          window.history.pushState({}, document.title, window.location.pathname);
          // @ts-ignore
          this.setIsLegacyUser(true);
          cookies.remove('token')
          cookies.remove('account_key')
      },
      async handleAccountKey(key, noWarning=false) {
        if (!noWarning) {
          let conf = confirm(
                  "You are restoring your account through an account key, the account you are currently logged into will be overwritten. Make sure that you have a backup of the magic link of this account. Click cancel to stop the import."
          );
          if (conf !== true) {
            return;
          }
        }
        cookies.remove('token')
        // @ts-ignore
        await this.retrieveJWTFromAccountKey({accountKey: key});
        // @ts-ignore
        this.setIsLegacyUser(true);
        window.history.pushState({}, document.title, window.location.pathname);
      },
      toShowCreateAccountDialog(newRoute) {
          return (((newRoute.path === '/3game' && newRoute.query.demo)
                // Exclusive games route check:
                || ['/slots', '/blackjack', '/roulette', '/keno', '/dice', '/craps'].includes(newRoute.path))
                // @ts-ignore
                && !this.userToken)

      },
      toShowPlayForRealDialog(newRoute) {
        // If player is playing any game for fun, we want to encourage them to play for real by showing a dialog after 2 minutes
        return (((newRoute.path === '/3game' && newRoute.query.demo)
                // Exclusive games route check:
                || ['/slots', '/blackjack', '/roulette', '/keno', '/dice', '/craps'].includes(newRoute.path))
                // @ts-ignore
                && this.userBalance === 0
                // @ts-ignore
                && this.userToken
                // @ts-ignore
                && !this.limitedAccount
                && !cookies.get('play_real_dialog_shown'))
      },
      getEndDate(date, days) {
        var new_date = moment(date,'YYYY-MM-DDTHH:mm:ss.SSSS').add(days, 'days')
        return new_date.format('YYYY-MM-DD HH:mm:ss')
      },
      async autoOptInIntoBannerCampaign(banner_id) {
        //auto campaign activate if player logs in for the first time and there is banner id stored in cookies from before
        //@ts-ignore
        let campaignsData = await this.getEligibleCampaigns();
        //@ts-ignore
        let bannerCampaigns = await this.getBannerCampaigns(banner_id);
        if(bannerCampaigns.length > 0) {
          let bannerCampaignData = bannerCampaigns[0];
            let payload = {
              'campaignsData': campaignsData.content,
              'bannerCampaignData': bannerCampaignData
            }
            //@ts-ignore
            let isCampaignFound = await this.isBannerCampaignEligible(payload);
            if(isCampaignFound) {
              //@ts-ignore
              await this.claimPromotionCampaign(bannerCampaignData.campaign_uuid);
              cookies.remove('firstLoginPending')
              if(bannerCampaignData.is_freespins) {
                let that = this;
                setTimeout(function () {
                  //@ts-ignore
                  that.getFreespins();
                }, 3000);
              }
            }
        }
      },
      async setAccountModalTab(tabIndex) {
        let dates = [moment().subtract(10, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
        //@ts-ignore
        this.accountModalSelectedTab = tabIndex;
        const getGameLogs = async (getAll) => {
          let payload = {
            'startDate': dates[0],
            'endDate': dates[1],
            'getAll': getAll,
            //@ts-ignore
            'platformCurrency': this.getPlatformCurrency
          }
          //@ts-ignore
          await this.getGameLogsData(payload);
        };
        if (tabIndex === 1) {
          try {
          // @ts-ignore
            await this.$refs.accountModalDialog.$refs.transactionsHistoryPanel.updateDates('all');
          } catch (e){
            console.warn('Problem fetching transactions when fetching from header', e)
          }
        }
        if (tabIndex === 4){
          //@ts-ignore
          await getGameLogs(false)
        }
      },
      skipWelcomeOfferPopup() {
          //@ts-ignore
          this.renderWelcomeOffer = false;
          localStorage.removeItem('welcomeOffer');
        },
      checkIsAnyWelcomePromoOptedIn(optedInCampaigns) {
        //@ts-ignore
        let welComeOfferPromotions = this.promotions.map(a => a.uuid);
        let result = optedInCampaigns.filter(
            function(data){ return welComeOfferPromotions.includes(data.uuid) }
        );
        if(result.length > 0) {
          return true
        }
        return false
      },
      getFreeSpinGameName(gameId) {
        //@ts-ignore
        let gamesFound = this.games.filter(game => game.gameId === gameId);
        if (gamesFound.length > 0) {
          return gamesFound[0].fullGameName;
        }
        return gameId;
      },
  },
})

@Component({
  components: {}
})

export default class App extends AppProps {
  @AccountGetter userId!: String
  @AccountGetter userToken!: String
  @AccountGetter email
  @AccountGetter userBalance
  @AccountGetter userIsActive
  @AccountGetter userAddress
  @AccountMutation setFreespinSeen
  @AccountMutation setUserIsActive
  @AccountGetter getFreespinsSeen
  @AccountGetter isLegacyUser
  @AccountGetter blockedCountriesList
  @AccountGetter fixedWithdrawalAddress!: String
  @AccountGetter depositIncomingCryptoAmount
  @UIGetter simpleFooterEnabled!: boolean
  @UIGetter footerDisabled!: boolean
  @AccountAction updateUserProfile
  @AccountAction getUserFreeSpins
  @AccountAction retrieveJWTFromAccountKey
  @AccountAction getUserData
  @AccountAction userIpCheck
  @AccountAction getBlockedCountries
  @AccountAction getDepositAddress
  @AccountAction getMagicTokenAndAuthData
  @AccountAction enableTwoFactorAuth
  @AccountAction getTotpSecretKey
  @AccountAction getFixedWithdrawalAddress
  @AccountAction getUserBalance
  @AccountAction hasPendingTx
  @AccountAction setSecuredByPassword
  @AccountAction setIsLegacyUser
  @AccountAction setPlayerLoginData
  @AccountAction reconfirmEmailPublic
  @DepositGetter depositHistory
  @GameLogsAction('getGameLogs') getGameLogsData
  @GamesAction initializeCasinoSession
  @GamesAction getPublicGamesList
  @GamesGetter games
  @SettingsGetter getPlatformCurrency
  @AccountAction btagTrack
  @AccountGetter identifier
  @AccountGetter emailVerified!: boolean
  @AccountGetter limitedAccount
  @AccountGetter isLoggedIn: boolean
  @AccountGetter userCountryCode
  @DepositAction("getAllDeposits") getDepositsData
  @DepositAction updateLastDeposit
  @ActivePromotionsCampaignGetter isAnyPromotionActive
  @AvailablePromotionsCampaignAction getEligibleCampaigns
  @AvailablePromotionsCampaignAction claimPromotionCampaign
  @AvailablePromotionsCampaignAction getBannerCampaigns
  @AvailablePromotionsCampaignAction isBannerCampaignEligible
  @AvailablePromotionsCampaignAction checkIsCampaignEligible
  @AvailablePromotionsCampaignAction getOptedInCampaigns
  @AccountAction resetPasswordRequest
  @AvailablePromotionsCampaignAction getAvailablePromotions
  @ActivePromotionsCampaignAction getActiveBonuses
  @PastPromotionsCampaignAction getPastBonuses
  @AvailablePromotionsCampaignAction getGeneralPromotions
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @Watch('$route', { immediate: true, deep: true })
    async onUrlChange(newRoute) {
      const setCid = async () => {
        if(this.$route.query.cid && !cookies.get('cid')) {
          if(!this.userId) {
            setTimeout(function(){ setCid() }, 2000);
          } else {
            await this.updateUserProfile({identifier: this.$route.query.cid})
            cookies.set('cid', this.$route.query.cid, {expires: 1/24})
            let pageUrl = window.location.href.split("?")[0];
            window.history.replaceState({}, document.title, pageUrl);
          }
        }
      }
      setCid()

      // @ts-ignore
      if (this.isLoggedIn) {
        if (newRoute.name != '3game') this.setBalanceDisabled(false)
        await this.getUserBalance();
        // Interval Set for every Half hours after login
        await this.setPlayerLoginData();
        window.setInterval(() => {
          this.setPlayerLoginData();
        }, 1000 * 60 * 30)
        const banner_id = cookies.get('banner_id')
        // Check if this is the first login for user, and sign user up for banner promotion if it is
        if (cookies.get('firstLoginPending')) {
          if (banner_id && (newRoute.path === '/' || newRoute.path === '/home')) {
            // @ts-ignore
            await this.autoOptInIntoBannerCampaign(banner_id);
          }
          ($("#header-control-deposit") as any).click();
          cookies.remove('firstLoginPending')
        }
      }
      // @ts-ignore
      if(this.toShowCreateAccountDialog(newRoute)) {
          window.setTimeout(() => {
          // If it's a third party game on mobile first make sure to exit fullscreen
          const iframe = document.querySelector('#third_party_game>iframe')
          if (iframe && /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent)) {
            // @ts-ignore
            this.closeFullscreen()
          }
          // @ts-ignore
          if (this.toShowCreateAccountDialog(this.$route)) {
            // @ts-ignore
            this.showCreateAccountDialog = true
            cookies.set('show_create_account_dialog', true)
          }
        }, 120000)
      } else {
        //@ts-ignore
        this.showCreateAccountDialog = false
        if (cookies.get('show_create_account_dialog')) {
          cookies.remove('show_create_account_dialog')
        }
      }
      // @ts-ignore
      if (this.toShowPlayForRealDialog(newRoute)) {
        //Show play for real dialog after one minute
        window.setTimeout(() => {
          // If it's a third party game on mobile first make sure to exit fullscreen
          const iframe = document.querySelector('#third_party_game>iframe')
          if (iframe && /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent)) {
            // @ts-ignore
            this.closeFullscreen()
          }
          // @ts-ignore
          if (this.toShowPlayForRealDialog(this.$route)) {
            // @ts-ignore
            this.showPlayRealDialog = true
            cookies.set('play_real_dialog_shown', true)
          }
        }, 120000)
      } else {
        // @ts-ignore
        this.showPlayRealDialog = false
        if (cookies.get('play_real_dialog_shown')) {
          cookies.remove('play_real_dialog_shown')
        }
      }
    }
}
