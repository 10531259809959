




























































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const SettingsGetter = namespace('settings').Getter

const AppProps = Vue.extend({
  computed: {
    dynamicDomain() {
      const currentUrl = window.location.href;
      if (currentUrl.includes("bitcoin.com")) {
        return "bitcoin.com";
      } else {
        return "bitcoingames1.com";
      }
    },
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Frequently Asked Questions for the Bitcoin Games online casino - How to Deposit, Cashout, Convert Cryptocurrencies, Play Games & many more`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `FAQ`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  props: {}
})
@Component({
  mounted () {
    let twitterScript = document.createElement('script')
    twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
    document.head.appendChild(twitterScript)
    let minus = '<path fill="#6A717F" d="M96 235h320v42H96z"></path>'
    let plus = '<path fill="#6A717F" d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"></path>'
    $('.faq-button').click((e) => {
      let item = e.currentTarget.parentNode
      if ($(item).hasClass('faq-open')) {
        $(item).find('svg').html(plus)
        $(item).find('.faq-textwrapper').css('height', '0px')
        $(item).removeClass('faq-open')
      } else {
        $(item).find('svg').html(minus)
        $(item).find('.faq-textwrapper').css('height', 'auto')
        $(item).addClass('faq-open')
      }
    })
  }
})
export default class Faq extends AppProps {
  @SettingsGetter getPlatformName!: String
  @SettingsGetter getPlatformCurrency!: String
}
