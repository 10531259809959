


















import { Component, Vue } from "vue-property-decorator";
import gql from 'graphql-tag'
import VueMarkdownIt from "vue-markdown-it";

const AppProps = Vue.extend({
  computed: {
    dynamicDomain() {
      const currentUrl = window.location.href;
      if (currentUrl.includes("bitcoin.com")) {
        return "bitcoin.com";
      } else {
        return "bitcoingames1.com";
      }
    },
    processedMarkdown() {
      const markdownKey = this.$i18n.locale === 'en' ? 'text' : `text_${this.$i18n.locale}`;
      const rawMarkdown = this.to[markdownKey] || "";
      // Replace {dynamicDomain} with the computed value
      return rawMarkdown.replace(/\{dynamicDomain\}/g, this.dynamicDomain);
    },
  },
  props: {},
  data: function(){
    return {
      to: {}
    }
  },
  mounted() {

  },
  apollo: {
    to: {
      // GraphQL Query
      query: gql`
        query{
          to {
            text
            text_ru
            text_es
            text_pt
          }
        }
      `,
    },
  },
  metaInfo: {
    meta: [
      {
        vmid: "description",
        name: "description",
        content: `Important Terms of Service for the Bitcoin Games online casino - Registered Company - Accepted Currencies - Governing Laws`,
      },
      {
        vmid: "title",
        name: "title",
        content: `Bitcoin Cash and Bitcoin Games`,
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: `terms of service, tos, bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`,
      },
    ],
  },
});
@Component({
  components: {
    VueMarkdownIt
  },
})
export default class Tos extends AppProps {}
